import { useContext, useEffect } from "react";
import { ConfigProvider, Empty, Button, theme } from "antd";
import { useNavigate } from "react-router-dom";
import UserContext from "context/user/UserContext";

/*
    This function can wrap around any ant design components to set a standard Empty state

    i.e.:
        <AdGeoConfigProvider to={`/some/url`}>
            <Table ... />
        </AdGeoConfigProvider>
*/

export default function AdGeoConfigProvider({ children, description = "No Items", to = "", cta = "Create" }) {
    const ctx = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {}, [ctx.isDarkTheme]);

    const empty =
        to === "" ? (
            <Empty imageStyle={{ height: 50 }} description={description} />
        ) : (
            <Empty imageStyle={{ height: 50 }} description={description}>
                <Button type="primary" onClick={() => navigate(to)}>
                    {cta}
                </Button>
            </Empty>
        );
    return (
        <ConfigProvider
            theme={{
                algorithm: ctx.isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
                token: ctx.isDarkTheme
                    ? { colorBgLayout: "linear-gradient(135deg, hsla(0, 0%, 0%, 1) 0%, hsla(220, 44%, 12%, 1) 50%, hsla(0, 0%, 0%, 1) 100%)", fontFamily: "Arial, sans-serif" }
                    : {
                          colorPrimary: "#4189E2",
                          colorBgLayout: "linear-gradient(135deg, hsla(0, 0%, 96%, 1) 0%, hsla(206, 100%, 95%, 1) 50%, hsla(0, 0%, 96%, 1) 100%)",
                          fontFamily: "Arial, sans-serif",
                      },
                components: {
                    Typography: {
                        fontSizeHeading1: 40,
                        fontWeightStrong: 300,
                    },
                    Layout: {
                        siderBg: "#3A3634",
                        lightSiderBg: "#3A3634",
                    },
                    Table: {
                        padding: 6,
                        paddingContentVerticalLG: 2,
                        fontSize: 12,
                    },
                    Menu: {
                        itemHeight: 30,
                        itemMarginBlock: 2,
                    },
                },
            }}
            renderEmpty={() => empty}
        >
            {children}
        </ConfigProvider>
    );
}
