import axios, { authHeader } from "./AxiosHelper";

// define public methods
export const userService = {
  // getUserInfo,
  get,
  getAll,
  getById,
  getSelf,
  post,
  put,
  getUserRoles,
  addRoleToUser,
  deleteRoleFromUser,
  updatePassword,
  updateUserAccountStatus,
  getProperties,
  postProperties,
  deleteUser,
  restoreUser,
  getPortalUserById,
  searchPortalUsers,
  getUserGroups,
  getUserEntities,
};

function get(page, pagesize, sorted, keyword, roleid, customerid, activeonly = true) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/user?page=${page}&pagesize=${pagesize}`;

    if (keyword && keyword.length > 0) url += "&keyword=" + escape(keyword);

    if (roleid && roleid.length > 0) url += "&roleid=" + roleid;

    if (customerid && customerid.length > 0) url += "&customerid=" + customerid;

    if (sorted)
      url +=
        "&sort=" +
        sorted.id +
        "&desc=" +
        (sorted.desc === "descend").toString();
    url += "&activeonly=" + activeonly;
    axios
      .get(url, requestOptions)
      .then((res) => {
        let item = res.data;
        resolve(item);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function getAll() {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/user/getall`;
    axios
      .get(url, requestOptions)
      .then((res) => {
        let item = res.data;
        resolve(item);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}
function getById(id) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/user/` + id;

    axios
      .get(url, requestOptions)
      .then((res) => {
        let item = res.data;
        resolve(item);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function getSelf() {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/user/me`;

    axios
      .get(url, requestOptions)
      .then((res) => {
        resolve(res.data);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function post(data) {
  const requestOptions = { headers: authHeader() };
  // add new
  if (data.id === undefined) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/user`, data, requestOptions)
        .then((res) => {
          resolve(res.data);
        })
        .catch((res) => {
          if (!res.response) reject("error");

          // returns an array of errors
          let m = res.response.data.map((o, idx) => {
            return o.description;
          });
          reject(m.join("\n"));
        });
    });
  }

  // update
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/user/` + data.id, data, requestOptions)
      .then((res) => {
        resolve(res.data);
      })
      .catch((res) => {
        if (!res.response) reject("error");

        // returns an array of errors
        let m = res.response.data.map((o, idx) => {
          return o.description;
        });
        reject(m.join("\n"));
      });
  });
}

/*
    // update one field in the user record
    Inputs:
        userid
        data: {
            name,
            systemName,
            value, 
            fieldType
        }
*/
function put(userid, data) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    axios
      .put(`/api/user/` + userid, data, requestOptions)
      .then((res) => {
        // get the token
        let oldUser = this.context.getUser();

        // copy over the token
        res.data.token = oldUser.token;

        // update the user in local storage with the new data
        localStorage.setItem(
          "user_info",
          window.btoa(JSON.stringify(res.data))
        );
        resolve(res);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function getUserRoles(id) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/user/` + id + `/roles`;

    axios
      .get(url, requestOptions)
      .then((res) => {
        let item = res.data;
        resolve(item);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function addRoleToUser(userId, roleId, startDate, expiryDate) {
  const requestOptions = { headers: authHeader() };

  let data = {
    userId,
    roleId,
    startDate,
    expiryDate,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(`/api/user/` + userId + `/role`, data, requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function deleteRoleFromUser(userId, roleId) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/user/` + userId + `/role/${roleId}`, requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function updatePassword(values) {
  const requestOptions = { headers: authHeader() };

  delete values.confirm;

  return new Promise((resolve, reject) => {
    axios
      .put(`/api/user/${values.id}/password`, values, requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        if (!res.response) reject("Error");

        // returns an array of errors
        let m = res.response.data.map((o, idx) => {
          return o.description;
        });

        reject(m.join("\n"));
      });
  });
}

function updateUserAccountStatus(userId, isLockedOut) {
  const requestOptions = { headers: authHeader() };
  return new Promise((resolve, reject) => {
    axios
      .put(
        `/api/user/${userId}/updateUserAccountStatus/${isLockedOut}`,
        requestOptions
      )
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        if (!res.response) reject("Error");

        // returns an array of errors
        let m = res.response.data.map((o, idx) => {
          return o.description;
        });

        reject(m.join("\n"));
      });
  });
}

function getProperties(id) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/user/` + id + `/properties`;

    axios
      .get(url, requestOptions)
      .then((res) => {
        resolve(res.data);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function postProperties(id, values) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    axios
      .post(`/api/user/${id}/properties`, values, requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        if (!res.response) reject("Error");

        // returns an array of errors
        let m = res.response.data.map((o, idx) => {
          return o.description;
        });

        reject(m.join("\n"));
      });
  });
}

function deleteUser(id) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/user/${id}`, requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function restoreUser(id) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    axios
      .put(`/api/user/${id}/restore`, null, requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

//#region Legacy - AdGeoPortal User
function getPortalUserById(id) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/user/getPortalUserById/` + id;

    axios
      .get(url, requestOptions)
      .then((res) => {
        let item = res.data;
        resolve(item);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function searchPortalUsers(searchString) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/user/searchportalusers?keyword=` + searchString;

    axios
      .get(url, requestOptions)
      .then((res) => {
        let item = res.data;
        resolve(item);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}
//#endregion

//#region Group/entities
function getUserGroups(userid) {
  return axios.getData(`/api/user/${userid}/groups`, true);
}

function getUserEntities(userid) {
  return axios.getData(`/api/user/${userid}/entitygroups`, true);
}
//#endregion
