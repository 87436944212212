export const columnSorter = function (a, b) {
    if (!isNaN(a) && !isNaN(b)){
        return a - b;
    }
    //console.log("a: " + a + " b: " + b);
    const aLower = a === null || a === '' ? a : a.toLowerCase();
    const bLower = b === null || b === '' ? b : b.toLowerCase();
    if (aLower < bLower) { return -1; }
    if (aLower > bLower) { return 1; }
    return 0;
}

export const multiFieldSelectFilter = function (input, option) {
    let inputLower = input.toLowerCase();
    let elements = option.props.children.filter(v => v !== ' ' && v !== '').map(v => isNaN(v) ? v.toLowerCase() : v);
    return elements.some(v => isNaN(v) ? v.indexOf(inputLower) >= 0 : v === input);
};

export const singleFieldSelectFilter = function (input, option) {
    let inputLower = input.toLowerCase();
    // console.dir(option.props);
    let elements = option.props.children.split(' ').filter(v => v !== ' ' && v !== '').map(v => isNaN(v) ? v.toLowerCase() : v);
    var exists = elements.some(v => v.indexOf(inputLower) >= 0);
    return exists;
};