import React from 'react';

class UserClaimConstants extends React.Component {
    static customer_view = 'client.view';
    static customer_edit = 'client.edit';
    static dashboard_view = 'dashboard.view';
    static masking_view = 'masking.view';
    static reporting_view = 'reporting.view';
    static reporting_save = 'reporting.save';
    static reporting_call_details = 'reporting.call.details';
    static network_view = 'network.view';
    static network_edit = 'network.edit';
    static number_view = 'number.view';
    static number_edit = 'number.edit';
    static location_view = 'location.view';
    static location_edit = 'location.edit';
    static npanxx_view = 'npanxx.view';
    static npanxx_edit = 'npanxx.edit';
    static zipcode_view = 'zipcode.view';
    static zipcode_edit = 'zipcode.edit';
    static postalcode_view = 'postalcode.view';
    static postalcode_edit = 'postalcode.edit';
    static state_view = 'state.view';
    static state_edit = 'state.edit';
    static county_view = 'county.view';
    static county_edit = 'county.edit';
    static historicalroute_view = 'historicalroute.view';
    static historicalroute_edit = 'historicalroute.edit';
    static dma_view = 'dma.view';
    static dma_edit = 'dma.edit';
    static tod_view = 'tod.view';
    static tod_edit = 'tod.edit';
    static rotationgroup_view = 'rotationgroup.view';
    static rotationgroup_edit = 'rotationgroup.edit';
}
export default UserClaimConstants;
