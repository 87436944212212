import React, { useContext, useEffect, useState } from "react";
import {
    DeploymentUnitOutlined,
    ShopOutlined,
    PhoneOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import { Menu, Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import UserContext from 'context/user/UserContext';

const { SubMenu } = Menu;

const SiteMenu = () => {
    const ctx = useContext(UserContext);
    const [menuItems, setMenu] = useState([]);

    useEffect(() => {
        // console.log("rebuilding menu");
        let m = [];
        let nav = ctx.menus.find(o => o.slug === "main-menu");
        let adminNav = ctx.menus.find(o => o.slug === "admin-menu");
        let historyNav = ctx.getHistoryMenu();
        let rn = ctx.menus.find(o => o.slug === "reporting-menu");

        if (nav) {
            m = nav.tree.map((o, i) => (
                <MenuItem {...o} key={`mainmenu${i}`} />
            ));
            m.push(<Menu.Divider key="mainspacer0" />);
        }

        if (rn) {
            m = m.concat(rn?.tree?.map((o, i) => (
                <MenuItem {...o} key={`reporting${i}`} />
            )));
        }

        if (adminNav) {
            m.push(<Menu.Divider key="adminspacer0" />);
            m = m.concat(adminNav.tree.map((o, i) => (
                <MenuItem {...o} key={`adminmenu${i}`} />
            )));
        }


        if (historyNav) {
            m.push(<Menu.Divider key="historyspacer0" />);
            m = m.concat(historyNav.map((o, i) => (
                <MenuItem {...o} key={`historymenu${i}`} />
            )));
        }
        setMenu(m);
    }, [ctx.menus.length]);

    return (
        menuItems.length > 0 ?
            <Menu inlineIndent={12} mode="inline" defaultOpenKeys={['reporting0', 'mainmenu1']} >
                {menuItems.map((o, i) => (
                    { ...o }
                ))}
            </Menu>
            : <span></span>
    )
}
export default SiteMenu;

function MenuItem(props) {
    if (Array.isArray(props.children) && props.children.length > 0) {
        return (
            // Spreading props here does the trick!
            <SubMenu icon={props.icon} title={props.name} {...props} >
                {props.children.map((item) => (
                    <MenuItem {...item} key={item.key} />
                ))}
            </SubMenu>
        );

    }
    return (
        // Spread here too!
        
        <Menu.Item key={props.key} {...props}>
            <Tooltip
                placement="right"
                title={props.tooltip}
                className="tooltip"
            >
                <NavLink to={props.url}>
                    {props.ico && props.ico === "PhoneOutlined" && <PhoneOutlined />}
                    {props.ico && props.ico === "DeploymentUnitOutlined" && <DeploymentUnitOutlined />}
                    {props.ico && props.ico === "ShopOutlined" && <ShopOutlined />}
                    {props.ico && props.ico === "ClockCircleOutlined" && <ClockCircleOutlined />}
                    <span>{props.title}</span>
                </NavLink>
            </Tooltip>
        </Menu.Item>
    );
}