import React from "react";
import * as ReactDOM from "react-dom/client";
import "antd/dist/reset.css";
import "./sass/index.scss";
import "./index.css";
import App from "./App";
import { createBrowserRouter, createRoutesFromElements, Routes, Route, RouterProvider } from "react-router-dom";
import UserState from "context/user/UserState";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter(createRoutesFromElements(<Route path="/*" element={<App />} />));

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <UserState>
            <RouterProvider router={router} />
        </UserState>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
