import React from "react";
import UserContext from "./UserContext";
import { userService } from "services/UserService";
import { settingsService } from "services/SettingsService";
import { reportingService } from "services/ReportingService";

class UserState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      system: {},
      user: {},
      roles: [],
      claims: [],
      menus: [],
      isAdmin: false,
      loading: false,
      token: "",
      maskedUserId: null,
      menuRenderKey: 33,
      isDarkTheme: localStorage.getItem("isDarkTheme") === "true",
    };
  }

  /// loads when page loads
  componentWillMount() {
    // if we have the localstorage info, then set the state using it
    let info =
      localStorage.getItem("user_info") &&
      JSON.parse(window.atob(localStorage.getItem("user_info")));
    if (info !== null) this.setUserState(info);

    // setup the masked user if there is one
    this.getMaskedUserId();

    // get system settings
    settingsService
      .get()
      .then((data) => {
        this.setState({
          system: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /// sets the loading var for api processing
  setLoading = (boo) => {
    this.setState({ loading: boo });
  };

  /// accepts a user object and serializes it to localstorage
  setUser = (user) => {
    localStorage.setItem("user_info", window.btoa(JSON.stringify(user)));
    this.setUserState(user);
  };

  /// sets various state vars for the site based on the user object
  setUserState = (user) => {
    let userRoles = user.userRoles;
    let navs = user.menus;
    let claims = user.claims;

    delete user.token;
    delete user.userRoles;
    delete user.menus;
    delete user.claims;

    this.setState({
      // first set roles
      roles: userRoles,
      user,
      menus: navs,
      claims: claims,
      isAdmin: user.isAdmin,
    });
  };

  // Get User
  getUser = () => {
    try {
      // if user is defined, then just return it
      // return localStorage.getItem('user_info') && JSON.parse(window.atob(localStorage.getItem('user_info')));
      if (this.state.user.id !== undefined) {
        return this.state.user;
      }

      return null;
    } catch (err) {
      localStorage.removeItem("user_info");
      return null;
    }
  };

  isAdmin = () => {
    return this.state.user.isAdmin;
  };

  loadProperties = () => {
    userService
      .loadProperties(this.user.id)
      .then((data) => {
        this.setState({
          properties: data,
        });

        return data;
      })
      .catch((err) => {
        return null;
      });
  };

  logout = () => {
    console.log("logging out");

    // if logged in via liberty, logout there
    let isLiberty = this.state.user.loginProvider === "liberty";
    // console.log(`${process.env.REACT_APP_LIBERTY_LOGOUT_URL}`);
    localStorage.removeItem("user_info");
    this.setState(
      {
        user: {},
        roles: [],
        claims: [],
        loading: false,
      },
      () => {
        if (isLiberty)
          window.location.href = `${process.env.REACT_APP_LIBERTY_LOGOUT_URL}`;
        else window.location.href = "/";
      }
    );
  };

  refreshUser = () => {
    userService
      .getById(this.user.id)
      .then((data) => {
        // copy over the token so we don't lose it
        let token = this.getUser().token;
        data.token = token;

        // resave the new data
        this.setUser(data);
        return data;
      })
      .catch((err) => {
        return null;
      });
  };

  refreshReportMenu = (callMeBack) => {
    // console.log("refresh report menu");
    reportingService
      .getReportMenu(this.getMaskedUserId())
      .then((data) => {
        let menus = this.state.menus;

        // remove existing reporting menu if it exists
        let idx = menus.findIndex((o) => o.slug === "reporting-menu");

        if (idx > -1) menus.splice(idx, 1);

        // add new menu
        menus.push(data);

        this.setState({
          menus,
          menuRenderKey: Math.random(),
        });
        if (callMeBack) {
          callMeBack();
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {});
  };

  getToken = () => {
    // return authorization header with jwt token
    let info = localStorage.getItem("user_info");
    let user = info !== null ? JSON.parse(window.atob(info)) : null;

    if (user && user.token) {
      return user.token;
    }
    return null;
  };
  //#region user masking
  /** gets the masked user, or authenticated user if not masking */
  getMaskedUserId = () => {
    // console.log("getting masked user");
    let id = localStorage.getItem("masked_user_info");
    if (!id || id === "undefined" || id === null || id === "null") {
      //because js stinks
      //   console.log("not masked as user, returning authenticated user");
      return this.state.user?.id;
    }
    // console.log("getting masked user " + id);
    return id;
  };

  /** sets the masked user id, if the id is invalid, will remove, calls refreshReportMenu after setting to ensure menu is regenerated
   * @param {string} id - id in the form of a guid to mask as
   */
  setMaskedUserId = (id) => {
    console.log("setting masked user id to " + id);
    if (!id || id === "undefined" || id === undefined) {
      //TODO: return if the id is invalid, i think this is a 🪲?
      console.log("remove masked user info");
      localStorage.removeItem("masked_user_info");
    }
    this.setState({ maskedUserId: id });
    localStorage.setItem("masked_user_info", id);

    // refresh the reporting menu
    // this.refreshReportMenu();
  };

  /** clears the masked user; removes the masked user id from state and local storage  */
  clearMaskedUserId = () => {
    this.setState({ maskedUserId: undefined });
    localStorage.removeItem("masked_user_info");
  };
  //#endregion

  //#region history menu
  getHistoryMenu = () => {
    // currently only admins get the history nav.  could be made into a claim or setting.
    let user =
      localStorage.getItem("user_info") &&
      JSON.parse(window.atob(localStorage.getItem("user_info")));

    if (!user || !this.isAdmin) return [];

    return JSON.parse(localStorage.getItem("trackedPages")) || [];
  };

  setHistoryMenu = (title, url, icon) => {
    // get array of saved pages
    var trackedPages = this.getHistoryMenu();

    // dump anything that matches this current page
    trackedPages = trackedPages.filter(function (value, index, arr) {
      return value.url !== url;
    });

    // track this page to top of the list
    trackedPages.unshift({
      title,
      url,
      ico: icon, // can't name it icon since that's special in antd
      key: url,
    });

    // max out at 10
    if (trackedPages.length > 10) trackedPages.length = 10;

    // save back to storage
    localStorage.setItem("trackedPages", JSON.stringify(trackedPages));
    this.refreshHistoryMenu();
  };

  refreshHistoryMenu = (callMeBack) => {
    // console.log("refresh history menu");

    let menus = this.state.menus;
    this.setState({
      menus,
      menuRenderKey: Math.random(),
    });
  };
  //#endregion
  hasClaim = (claimName) => {
    const user = this.getUser();
    if (!user) {
      return false;
    }
    var userClaims = user.claimsString;
    let userClaimLookup = (str) =>
      userClaims.filter((claim) => claim.includes(str));

    var hasClaim = userClaimLookup(claimName).length === 0 ? false : true;
    return hasClaim;
  };

  toggleTheme = () => {
    localStorage.setItem("isDarkTheme", !this.state.isDarkTheme);
    this.setState({ isDarkTheme: !this.state.isDarkTheme });
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          system: this.state.system,
          user: this.state.user,
          roles: this.state.roles,
          claims: this.state.claims,
          menus: this.state.menus,
          loading: this.state.loading,
          token: this.state.token,
          historyMenu: this.state.historyMenu,
          maskedUserId: this.state.maskedUserId,
          menuRenderKey: this.state.menuRenderKey,
          isDarkTheme: this.state.isDarkTheme,

          setUser: this.setUser,
          getUser: this.getUser,
          refreshReportMenu: this.refreshReportMenu,
          refreshUser: this.refreshUser,
          setLoading: this.setLoading,
          loadProperties: this.loadProperties,
          getToken: this.getToken,
          logout: this.logout,
          getHistoryMenu: this.getHistoryMenu,
          setHistoryMenu: this.setHistoryMenu,
          setMaskedUserId: this.setMaskedUserId,
          getMaskedUserId: this.getMaskedUserId,
          clearMaskedUserId: this.clearMaskedUserId,
          isAdmin: this.isAdmin,
          hasClaim: this.hasClaim,
          toggleTheme: this.toggleTheme,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserState;
