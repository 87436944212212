import axios, { authHeader } from "./AxiosHelper";

// define public methods
export const reportingService = {
  search,
  getReportParameters,
  upsertReport,
  searchReportSets,
  upsertReportSet,
  addReportToSet,
  deleteReportFromSet,
  searchReportGroups,
  upsertReportGroup,
  addSetToGroup,
  deleteSetFromGroup,
  addUserToGroup,
  deleteUserFromGroup,
  addGroupToCustomer,
  deleteGroupFromCustomer,

  getById,
  getReportMenu,
  loadCallDetails,
  loadCallRecording,
  runReport2,
  getSetValues,
  addSavedReport,
  updateSavedReport,
  getSavedReportById,
  deleteSavedReport,
  getSelectedValuesForParameter,
  getSubscription,
  updateSubscription,
  searchChangeTracking,
  getChangeTrackingTableTypes,

  searchEntities,
  upsertEntity,
  searchEntityGroups,
  upsertEntityGroup,
  addEntityToEntityGroup,
  deleteEntityFromEntityGroup,
  addUserToEntityGroup,
  deleteUserFromEntityGroup,

  getReportExportTypes,
  addExportTypeToReport,
  deleteExportTypeFromReport,
};

//#region Crud
function search(args) {
  return axios.postData(`/api/reporting/search`, args, true);
}

function getReportParameters() {
  return axios.getData(`/api/reporting/parameters`, true);
}

function upsertReport(values) {
  return axios.upsertData(values.id, `/api/reporting`, values, true);
}

function getById(id) {
  return axios.getData(`/api/reporting/${id}`, true);
}

function searchReportSets(args) {
  return axios.postData(`/api/reporting/searchReportSets`, args, true);
}

function upsertReportSet(values) {
  return axios.upsertData(values.id, `/api/reporting/reportset`, values, true);
}

function addReportToSet(values) {
  return axios.putData(
    `/api/reporting/reportset/${values.id}/report`,
    values,
    true
  );
}

function deleteReportFromSet(id, reportid) {
  return axios.deleteData(
    `/api/reporting/reportset/${id}/report/${reportid}`,
    true
  );
}

function searchReportGroups(args) {
  return axios.postData(`/api/reporting/groups/search`, args, true);
}

function upsertReportGroup(values) {
  return axios.upsertData(values.id, `/api/reporting/groups`, values, true);
}

function addSetToGroup(values) {
  return axios.putData(`/api/reporting/groups/${values.id}/set`, values, true);
}

function deleteSetFromGroup(id, setid) {
  return axios.deleteData(`/api/reporting/groups/${id}/set/${setid}`, true);
}

function addUserToGroup(values) {
  return axios.putData(
    `/api/reporting/groups/${values.groupId}/user`,
    values,
    true
  );
}

function deleteUserFromGroup(id, userid) {
  return axios.deleteData(`/api/reporting/groups/${id}/user/${userid}`, true);
}

function addGroupToCustomer(values) {
  return axios.postData(
    `/api/reporting/groups/${values.groupId}/customer`,
    values,
    true
  );
}

function deleteGroupFromCustomer(groupid, customerid) {
  return axios.deleteData(
    `/api/reporting/groups/${groupid}/customer/${customerid}`,
    true
  );
}

function getReportMenu(userId) {
  return axios.getData(`/api/reporting/reportmenu/${userId}`, true);
}

function loadCallDetails(args) {
  return axios.postData(`/api/reporting/calldetails`, args, true);
}

function loadCallRecording(id, redacted = false) {
  return axios.getData(`/api/callrecording/${id}?redacted=${redacted}`, true);
}

function runReport2(data, acceptParam) {
  let info = localStorage.getItem("user_info");
  let user = info !== null ? JSON.parse(window.atob(info)) : null;

  const requestOpts = {
    headers: {
      "Content-Type": "application/json",
      Accept: acceptParam,
      Authorization: "Bearer " + user.token,
    },
    responseType: "blob",
  };

  return new Promise((resolve, reject) => {
    const url = "/api/reporting/runreport";
    axios
      .post(url, data, requestOpts)
      .then((res) => {
        let post = res.data;
        resolve(post);
      })
      .catch((res) => {
        console.log("error ");
        reject(res.response ? res.response.data : "error");
      });
  });
}

function getSetValues(userId, setName) {
  return axios.getData(`/api/reporting/${setName}/${userId}`, true);
}

function getReportExportTypes() {
  return axios.getData(`/api/reporting/exporttypes`, true);
}

function addExportTypeToReport(id, reportid) {
  return axios.putData(
    `/api/reporting/reportexporttype/${id}/report/${reportid}`,
    true
  );
}

function deleteExportTypeFromReport(id, reportid) {
  return axios.deleteData(
    `/api/reporting/reportexporttype/${id}/report/${reportid}`,
    true
  );
}
//#endregion

//#region saved reports
// this saves a NEW report or posts
function addSavedReport(data) {
  const requestOptions = { headers: authHeader() };
  return new Promise((resolve, reject) => {
    axios
      .post("/api/reporting/savereport", data, requestOptions)
      .then((res) => {
        let post = res.data;
        resolve(post);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function updateSavedReport(data) {
  const requestOptions = { headers: authHeader() };
  console.dir(data);
  console.log("savedReportId " + data.savedReportId);
  // update
  return new Promise((resolve, reject) => {
    axios
      .put(
        `/api/reporting/savereport/${data.savedReportId}`,
        data,
        requestOptions
      )
      .then((res) => {
        resolve(data);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function getSavedReportById(id) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/reporting/savedreports/${id}`;

    axios
      .get(url, requestOptions)
      .then((res) => {
        resolve(res.data);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}
function deleteSavedReport(id) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/reporting/savedreports/${id}`;

    axios
      .delete(url, requestOptions)
      .then((res) => {
        resolve(res.data);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}

function getSelectedValuesForParameter(id, parameterName) {
  const requestOptions = { headers: authHeader() };

  return new Promise((resolve, reject) => {
    let url = `/api/reporting/savedreportvals/${id}/?parameterName=${parameterName}`;

    axios
      .get(url, requestOptions)
      .then((res) => {
        resolve(res.data);
      })
      .catch((res) => {
        reject(res.response ? res.response.data : "error");
      });
  });
}
//#endregion

//#region Subscriptions
function getSubscription(savedReportId) {
  return axios.getData(`/api/reporting/subscription/${savedReportId}`, true);
}

function updateSubscription(values) {
  return axios.upsertData(
    values.id,
    `/api/reporting/subscription/${values.savedReportId}`,
    values,
    true
  );
}
//#endregion

//#region ChangeTracking
function searchChangeTracking(args) {
  return axios.postData(`/api/reporting/searchchangetracking`, args, true);
}

function getChangeTrackingTableTypes() {
  return axios.getData(`/api/reporting/changetracking/tabletypes`, true);
}
//#endregion

//#region Entities
function searchEntities(data) {
  return axios.postData(`/api/reportingentity/entity/search`, data, true);
}

function upsertEntity(values) {
  return axios.upsertData(
    values.id,
    `/api/reportingentity/entity`,
    values,
    true
  );
}

function searchEntityGroups(data) {
  return axios.postData(`/api/reportingentity/entitygroup/search`, data, true);
}

function upsertEntityGroup(values) {
  return axios.upsertData(
    values.id,
    `/api/reportingentity/entitygroup`,
    values,
    true
  );
}

function addEntityToEntityGroup(data) {
  return axios.putData(
    `/api/reportingentity/entitygroup/${data.entityGroupId}/entity`,
    data,
    true
  );
}

function deleteEntityFromEntityGroup(entityGroupJoinId) {
  return axios.deleteData(
    `/api/reportingentity/entitygroup/${entityGroupJoinId}/entity`,
    true
  );
}

function addUserToEntityGroup(data) {
  return axios.putData(
    `/api/reportingentity/entitygroup/${data.entityGroupId}/user`,
    data,
    true
  );
}

function deleteUserFromEntityGroup(id) {
  return axios.deleteData(`/api/reportingentity/entitygroup/${id}/user`, true);
}
//#endregion
