import React, { useContext, useEffect, lazy } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { IntlProvider } from "react-intl";
import Layout from "components/MasterLayout";
import AdGeoConfigProvider from "util/AdGeoConfigProvider";
import ErrorBoundary from "util/ErrorBoundary";
import { isIOS, isMobile } from "react-device-detect";
import UserContext from "context/user/UserContext";
import AppLocale from "util/LangProvider";

const MainApp = lazy(() => import("pages/app/index"));
const ManageApp = lazy(() => import("pages/manage/index"));
const ReportApp = lazy(() => import("pages/reports/index"));
const Login = lazy(() => import("pages/misc/Login"));
const SsoLiberty = lazy(() => import("pages/misc/SsoLiberty"));
const CallRecording = lazy(() => import("pages/misc/callrecording"));
// const ConfirmEmail = lazy(() => import( './pages/misc/confirmEmail')); // used when new people register
const ConfirmPassword = lazy(() => import("./pages/misc/confirmPassword"));
const PageNotFound = lazy(() => import("./pages/misc/404"));

export default function App() {
  const ctx = useContext(UserContext);
  const location = useLocation();
  let authUser = ctx.getUser();

  useEffect(() => {
    // per environment, then add class to change the bg color
    if (window.location.href.indexOf("localhost") > -1)
      document.getElementById("root").className += " root-dev";
    if (window.location.href.indexOf("staging") > -1)
      document.getElementById("root").className += " root-staging";
  }, []);

  if (location.pathname === "/") {
    if (authUser === null) {
      return <Navigate to={"/login"} />;
    } else {
      return <Navigate to={"/app/dashboard/default"} />;
    }
  }

  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add("ios-mobile-view-height");
  } else if (document.body.classList.contains("ios-mobile-view-height")) {
    document.body.classList.remove("ios-mobile-view-height");
  }

  const currentAppLocale = AppLocale["en"];
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <AdGeoConfigProvider>
        <div id="app-main" className="app-main">
          {
            // to handle the not collapsing all the time as you bounce around. surround the layout in all the logged in trunks
            location.pathname.indexOf("/app") > -1 ||
            location.pathname.indexOf("/manage") > -1 ||
            location.pathname.indexOf("/reports") > -1 ||
            location.pathname.indexOf("/call-recording") > -1 ||
            location.pathname.indexOf("/call-recording-redacted") > -1 ? (
              <RequireAuth redirectTo="/login" authUser={authUser}>
                <Layout>
                  <ErrorBoundary>
                    <Routes>
                      <Route path={`app/*`} element={<MainApp />} />
                      <Route path={`manage/*`} element={<ManageApp />} />
                      <Route path={`reports/*`} element={<ReportApp />} />
                      <Route
                        path={`call-recording/:id`}
                        element={<CallRecording redacted={false}/>}
                      />
                      <Route
                        path={`call-recording-redacted/:id`}
                        element={<CallRecording redacted={true}/>}
                      />
                    </Routes>
                  </ErrorBoundary>
                </Layout>
              </RequireAuth>
            ) : (
              <ErrorBoundary>
                <Routes>
                  <Route path={`login`} element={<Login />} />
                  <Route path={`ssoliberty/:id?`} element={<SsoLiberty />} />
                  <Route
                    path={`confirmpassword`}
                    element={<ConfirmPassword />}
                  />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </ErrorBoundary>
            )
          }
        </div>
      </AdGeoConfigProvider>
    </IntlProvider>
  );
}

function RequireAuth({ children, redirectTo, authUser }) {
  return authUser ? children : <Navigate to={redirectTo} />;
}
