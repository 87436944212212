import React, { useContext, useEffect, useState } from "react";
import {
  LogoutOutlined,
  UserOutlined,
  MenuFoldOutlined,
  BulbOutlined,
} from "@ant-design/icons";
import { Layout, Switch, Dropdown, Avatar, Button, Input, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import UserContext from "context/user/UserContext";
import SiteMenu from "./SiteMenu";
import { userService } from "services/UserService.js";

import { multiFieldSelectFilter } from "util/Shared";
import UserClaimConstants from "context/user/UserClaimConstants";

const { Search } = Input;
const { Content, Header, Sider } = Layout;

const emptyUser = {
  id: "00000000-0000-0000-0000-000000000000",
  firstName: "",
  lastName: "",
};

export default function MasterLayout({ children }) {
  const ctx = useContext(UserContext);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [keyword, setKeyword] = useState("");
  const [userList, setUserList] = useState([emptyUser]);
  const [loadingMaskingUserList, setLoadingMaskingUserList] = useState(false);

  useEffect(() => {
    loadMaskingUserList();
    ctx.refreshReportMenu();

    // put a watcher on the width of the window
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      if (!collapsed && window.innerWidth < 992) setCollapsed(true);
      else if (window.innerWidth >= 992) setCollapsed(false);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  //#region toggles
  const toggle = () => {
    if (window.innerWidth >= 992) setCollapsed(!collapsed);
  };

  const userSignOut = (e) => {
    ctx.logout();
    e.preventDefault();
    return false;
  };
  //#endregion

  const handleSearch = (val) => {
    let encoded = encodeURI(val);
    navigate(`/app/searchresults/${encoded}`);
  };

  /* mask user related */
  const handleMaskUserChange = (id) => {
    console.log("masking user changed to: " + id);
    ctx.setMaskedUserId(id);
    window.location.reload();
  };

  const loadMaskingUserList = () => {
    //if authenticated user has masking.view claim, then load users
    if (ctx.isAdmin() || ctx.hasClaim(UserClaimConstants.masking_view)) {
      setLoadingMaskingUserList(true);
      userService
        .getAll()
        .then((data) => {
          setUserList(data);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoadingMaskingUserList(false);
        });
    }
  };

  //#endregion

  const userSelectList = userList?.map((p) => (
    <Select.Option key={p.id} value={p.id}>
      ({p.legacyReportingCid}) {p.userName} - {p.firstName} {p.lastName}
    </Select.Option>
  ));

  const userMenuItems = [
    {
      label: (
        <Link className="dropdown-item text-muted" to="/app/user">
          <UserOutlined className="mr-1" />
          <IntlMessages id="popup.profile" />
        </Link>
      ),
      key: "user",
    },
    {
      label: (
        <a className="dropdown-item text-muted" href="/" onClick={userSignOut}>
          <LogoutOutlined className="mr-1" />
          <IntlMessages id="popup.logout" />
        </a>
      ),
      key: "logout",
    },
    { type: "divider" },
    {
      label: (
        <Switch
          checkedChildren={<BulbOutlined />}
          unCheckedChildren={<BulbOutlined />}
          onClick={() => ctx.toggleTheme()}
          checked={!ctx.isDarkTheme}
        />
      ),
      key: "theme",
    },
    {
      label: `Version: ${ctx.system && ctx.system.version}`,
      key: "version",
    },
  ];

  let thumbnail =
    ctx.user.thumbnailUrl !== "" ? (
      <Avatar size={50} src={ctx.user.thumbnailUrl} />
    ) : (
      <Avatar size={50} icon={<UserOutlined />} class="light" />
    );

  const maskAsUserSelect =
    ctx.isAdmin() || ctx.hasClaim(UserClaimConstants.masking_view) ? (
      <Select
        showSearch
        filterOption={multiFieldSelectFilter}
        optionFilterProp="children"
        value={ctx.getMaskedUserId()}
        placeholder="mask as user"
        className="mr-4"
        style={{ width: 400 }}
        defaultActiveFirstOption={false}
        allowClear={true}
        loading={loadingMaskingUserList}
        onChange={handleMaskUserChange}
        notFoundContent={null}
        disabled={loadingMaskingUserList}
      >
        {userSelectList}
      </Select>
    ) : (
      ""
    );
  const siteSearch = ctx.isAdmin() && (
    <div className="d-ib mr-4">
      <Search
        id="keyword"
        placeholder="search"
        onSearch={handleSearch}
        enterButton
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
    </div>
  );

  return (
    <Layout className="wrapper" hasSider={true}>
      <Sider
        width={400}
        collapsedWidth={0}
        collapsible={true}
        collapsed={collapsed}
        breakpoint="lg"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 65,
          bottom: 0,
        }}
      >
        <SiteMenu key={ctx.menuRenderKey} />
      </Sider>

      <Layout className="site-layout">
        <Header className="navbar">
          <Link to={"/app/dashboard/default"} className="navbar-logo-container">
            <img
              src="/assets/images/logo.png"
              className="navbar-logo"
              alt="logo"
            />
          </Link>

          <div className="">
            <Button
              onClick={() => toggle()}
              icon={<MenuFoldOutlined />}
            ></Button>
          </div>
          <div className="ml-auto mr-0">
            {maskAsUserSelect}
            {siteSearch}
            <Dropdown trigger={["click"]} menu={{ items: userMenuItems }}>
              {thumbnail}
            </Dropdown>
          </div>
        </Header>
        <Content className="container">{children}</Content>
      </Layout>
    </Layout>
  );
}
