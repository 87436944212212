import axios, { authHeader } from './AxiosHelper';

// define public methods
export const settingsService = {
    get,
    search,
    post,
    deleteSetting,
    getMenus,
    getMenuById,
    postMenu,
    deleteMenu
};

//#region Crud
function get() {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {

        let url = `/api/settings`;

        axios.get(url, requestOptions)
            .then(res => {
                resolve(res.data);
            })
            .catch(res => {
                reject(res.response ? res.response.data : "error");
            });
    });
}

function search(data) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {

        let url = `/api/settings/search`;

        axios.post(url, data, requestOptions)
            .then(res => {
                resolve(res.data);
            })
            .catch(res => {
                reject(res.response ? res.response.data : "error");
            });
    });
}

function post(data) {

    const requestOptions = { headers: authHeader() };

    // add new
    if (data.id === undefined) {
        return new Promise((resolve, reject) => {

            axios.post(`/api/settings`, data, requestOptions)
                .then(res => {
                    let post = res.data;
                    resolve(post);
                })
                .catch(res => {
                    reject(res.response ? res.response.data : "error");
                })
        })
    }

    // update
    return new Promise((resolve, reject) => {

        axios.put(`/api/settings/${data.id}`, data, requestOptions)
            .then(res => {
                resolve(data);
            })
            .catch(res => {
                reject(res.response ? res.response.data : "error");
            });
    });
}


function deleteSetting(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        axios.delete(`/api/settings/${id}`, requestOptions)
            .then(res => {
                resolve(res.data);
            })
            .catch(res => {
                reject(res.response ? res.response.data : "error");
            });
    });
}
//#endregion

//#region Menus
function getMenus() {
    return axios.getData(`/api/settings/menu`, false);
}

function getMenuById(id) {
    return axios.getData(`/api/settings/menu/${id}`, false);
}

function postMenu(data) {
    return axios.upsertData(data.id, `/api/settings/menu`, data, true);
}

function deleteMenu(id) {
    return axios.deleteData(`/api/settings/menu/${id}`, true);
}
//#endregion

